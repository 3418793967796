import axios from "axios";
import store from "./store/index";
import Swal from "sweetalert2/dist/sweetalert2";

let baseURL = "/api/v1/";
if (process.env.NODE_ENV === "development")
  baseURL = process.env.VUE_APP_BACKEND_URL;
const base = axios.create({
  baseURL: baseURL,
});

base.interceptors.request.use((config) => {
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
    if (user.token.plainTextToken)
      config.headers["Authorization"] = `Bearer ${user.token.plainTextToken}`;
  }
  return config;
});

base.interceptors.response.use(
  (response) => response,
  async (error) => {
    let title = "Oops...";
    let message = "";
    console.log(error);
    if (error.code == "ERR_NETWORK") {
      error
    } else if (error.response && error.response.status === 401) {
      store.dispatch("auth/clearUser")
      message = error.response.data.error.message;
    } else if (error.response && error.response.status === 403) {
      if (
        typeof error.response.data == "object" &&
        error.request.responseType == "blob"
      ) {
        const err_text = await error.response.data.text();
        const err_json = JSON.parse(err_text);

        if (err_json && err_json.title) title = err_json.title;
        if (err_json && err_json.message) message = err_json.message;
      } else {
        if (error.response && error.response.data && error.response.data.title)
          title = error.response.data.title;

        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        )
          message = error.response.data.message;
        else if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.message
        )
          message = error.response.data.error.message;
      }
    } else if (error.response && error.response.status === 500) {
      if (error.response && error.response.data && error.response.data.error)
        message = error.response.data.error.message;
      else if (
        error.request.responseType == "blob" &&
        error.response &&
        error.response.data &&
        isJson(await error.response.data.text())
      ) {
        const response = JSON.parse(await error.response.data.text());
        if (response.message) message = response.message;
        if (response.title) title = response.title;
      }

      if (message == "" || message == null || message == undefined)
        message = error.message;
    }

    if (message == "" || message == null || message == undefined)
      message = "Something went wrong!";

    if (error.response && error.response.status !== 422) {
      if (error.config.interceptor_toast) {
        // console.log(title, message)
        alert(message);
      } else {
        new Swal({
          icon: "error",
          title: title,
          text: message,
        });
      }
    }

    return Promise.reject(error);
  }
);

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export default base;
