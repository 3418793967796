const breadcrumb = (state) => {
  return state.breadcrumb;
};

const modules = (state) => {
  return state.modules;
};

const navigations = (state) => {
  return state.navigations;
};

export default {
  breadcrumb,
  modules,
  navigations,
};
