import axios from "../../axios";

const updateBreadcrumb = (context, breadcrumb) => {
  return context.commit("updateBreadcrumb", breadcrumb);
};

const getAuthModules = async (context, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .get("auth/get-auth-modules", payload)
      .then((res) => {
        if (res.data.success) {
          context.commit("updateModules", {
            modules: res.data.module_codes,
          });

          // TODO: dapat dito yung evaluateNavigations
          evaluateNavigations(context);
        }

        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const evaluateNavigations = (context) => {
  const navigations_length = context.state.navigations.length;
  for (
    let navigation_index = 0;
    navigation_index < navigations_length;
    navigation_index++
  ) {
    const navigation = context.state.navigations[navigation_index];
    const required_modules_length = navigation.required_modules.length;
    for (
      let required_module_index = 0;
      required_module_index < required_modules_length;
      required_module_index++
    ) {
      if (
        context.state.modules.includes(
          navigation.required_modules[required_module_index]
        )
      ) {
        context.commit("updateNavigationShow", {
          index: navigation_index,
          show: true,
        });
        break;
      }
    }
  }
};

export default {
  updateBreadcrumb,
  getAuthModules,
};
