const setUser = (state, payload) => {
  state.user = payload.user;

  // Save user data to local storage to remember user credentials
  localStorage.setItem("user", JSON.stringify(payload));
};

const clearUser = () => {
  localStorage.removeItem("user");
  location.reload();
};

const updateUser = (state, payload) => {
  state.user = payload.user;

  let user = JSON.parse(localStorage.getItem("user"));
  user.user = payload.user;
  localStorage.setItem("user", JSON.stringify(user));
};

const updateToken = (state, token) => {
  localStorage.clear();
  let user = {
    token: {
      plainTextToken: token,
    },
  };
  localStorage.setItem("user", JSON.stringify(user));
};

export default {
  setUser,
  clearUser,
  updateUser,
  updateToken,
};
