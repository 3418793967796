<template>
  <header class="d-none" id="navigation_bar">
    <img id="logo" src="./assets/images/logo_neda.svg" alt="Logo" />
    <nav class="navbar">
      <ul class="nav__links">
        <li data-expand="hr_system" class="nav--link">Human Resource</li>
        <!-- <li data-expand="use-cases" class="nav--link">Use Cases</li>
        <li data-expand="developers" class="nav--link">Developers</li>
        <li data-expand="company" class="nav--link">Company</li> -->
      </ul>
    </nav>

    <button class="btn btn--primary" id="logout_button">
      <span>Logout</span>
      <div class="arrow">
        <div class="line arrow--sideUp"></div>
        <div class="line arrow--sideDown"></div>
        <div class="line arrow--hoverLine"></div>
      </div>
    </button>

    <div class="tip"></div>
    <section class="header__expandMenu">
      <div class="menu__container">
        <div id="hr_system">
          <div class="sub__menu" data-module="Job Posting">
            <h3 class="title">Job Posting</h3>
            <ul class="subMenu__items">
              <li class="subMenu--item" data-route-name="Job Posting Datatable">
                <img src="./assets/images/payment.svg" alt="" />
                <span class="label__container">
                  <div class="label">
                    Job Posting Masterlist
                    <img
                      class="right--arrow"
                      src="./assets/images/right-arrow.svg"
                      alt=""
                    />
                  </div>
                  <div class="label--desc">Job Posting Records</div>
                </span>
              </li>

              <li class="subMenu--item" data-route-name="Job Posting Form">
                <img src="./assets/images/terminal.svg" alt="" />
                <span class="label__container">
                  <div class="label">
                    Job Posting Form
                    <img
                      class="right--arrow"
                      src="./assets/images/right-arrow.svg"
                      alt=""
                    />
                  </div>
                  <div class="label--desc">Add New Job Posting</div>
                </span>
              </li>

              <li
                class="subMenu--item"
                data-route-name="Job Posting Masterlist"
              >
                <img src="./assets/images/connect.svg" alt="" />
                <span class="label__container">
                  <div class="label">
                    Applications Masterlist
                    <img
                      class="right--arrow"
                      src="./assets/images/right-arrow.svg"
                      alt=""
                    />
                  </div>
                  <div class="label--desc">Application Records</div>
                </span>
              </li>

              <li
                class="subMenu--item"
                data-route-name="Job Posting Masterlist Summary"
              >
                <img src="./assets/images/billing.svg" alt="" />
                <span class="label__container">
                  <div class="label">
                    Applications Masterlist Summary
                    <img
                      class="right--arrow"
                      src="./assets/images/right-arrow.svg"
                      alt=""
                    />
                  </div>
                  <div class="label--desc">Applications Summary</div>
                </span>
              </li>
            </ul>
          </div>

          <div class="sub__menu" data-module="Leave Management">
            <h3 class="title">Leave Management</h3>
            <ul class="subMenu__items">
              <li
                class="subMenu--item"
                data-route-name="Leave Management Datatable"
              >
                <img src="./assets/images/payouts.svg" alt="" />
                <span class="label__container">
                  <div class="label">
                    Leave Management
                    <img
                      class="right--arrow"
                      src="./assets/images/right-arrow.svg"
                      alt=""
                    />
                  </div>
                  <div class="label--desc">Employee Leave Records</div>
                </span>
              </li>

              <li class="subMenu--item" data-route-name="Leave Management Form">
                <img src="./assets/images/issuing.svg" alt="" />
                <span class="label__container">
                  <div class="label">
                    Leave Credit Form
                    <img
                      class="right--arrow"
                      src="./assets/images/right-arrow.svg"
                      alt=""
                    />
                  </div>
                  <div class="label--desc">
                    Adde Employee Initial Leave Credits
                  </div>
                </span>
              </li>
            </ul>
          </div>

          <!-- <div class="sub__menu">
            <h3 class="title">BUISNESS OPERATIONS</h3>
            <ul class="subMenu__items">
              <li class="subMenu--item">
                <img src="./assets/images/radar.svg" alt="" />
                <span class="label__container">
                  <div class="label">
                    Radar
                    <img
                      class="right--arrow"
                      src="./assets/images/right-arrow.svg"
                      alt=""
                    />
                  </div>
                  <div class="label--desc">Fraud & risk management</div>
                </span>
              </li>

              <li class="subMenu--item">
                <img src="./assets/images/sigma.svg" alt="" />
                <span class="label__container">
                  <div class="label">
                    Sigma
                    <img
                      class="right--arrow"
                      src="./assets/images/right-arrow.svg"
                      alt=""
                    />
                  </div>
                  <div class="label--desc">Custom reports</div>
                </span>
              </li>

              <li class="subMenu--item">
                <img src="./assets/images/atlas.svg" alt="" />
                <span class="label__container">
                  <div class="label">
                    Atlas
                    <img
                      class="right--arrow"
                      src="./assets/images/right-arrow.svg"
                      alt=""
                    />
                  </div>
                  <div class="label--desc">Start-up incorporation</div>
                </span>
              </li>
            </ul>
          </div> -->
        </div>

        <div id="use-cases">
          <ul class="subMenu__items">
            <li class="subMenu--item">
              <img
                class="icon--production"
                src="./assets/images/saas.svg"
                alt=""
              />
              <span class="label__container">
                <div class="label">
                  SaaS
                  <img
                    class="right--arrow"
                    src="./assets/images/right-arrow.svg"
                    alt=""
                  />
                </div>
                <div class="label--desc">
                  Manage recurring bills and subscriptions
                </div>
              </span>
            </li>

            <li class="subMenu--item">
              <img
                class="icon--production"
                src="./assets/images/marketplace.svg"
                alt=""
              />
              <span class="label__container">
                <div class="label">
                  Marketplaces
                  <img
                    class="right--arrow"
                    src="./assets/images/right-arrow.svg"
                    alt=""
                  />
                </div>
                <div class="label--desc">
                  Pay out globally and facilitate multi-party payments
                </div>
              </span>
            </li>

            <li class="subMenu--item">
              <img
                class="icon--production"
                src="./assets/images/platform.svg"
                alt=""
              />
              <span class="label__container">
                <div class="label">
                  Platform
                  <img
                    class="right--arrow"
                    src="./assets/images/right-arrow.svg"
                    alt=""
                  />
                </div>
                <div class="label--desc">
                  Let customers accept payments within your platform
                </div>
              </span>
            </li>
          </ul>
        </div>

        <div id="developers">
          <div class="subMenu--item item--one">
            <img
              class="icon--production"
              src="./assets/images/documentation.svg"
              alt=""
            />
            <span class="label__container">
              <div class="label">
                Documentation
                <img
                  class="right--arrow"
                  src="./assets/images/right-arrow.svg"
                  alt=""
                />
              </div>
              <div class="label--desc">
                Start integrating stripe's products and tools
              </div>
            </span>
          </div>

          <div class="sec__links--container">
            <h4 class="sec__title">GET STARTED</h4>

            <ul class="sec__links">
              <li class="sec__link">Prebuilt checkout</li>
              <li class="sec__link">Liberaries and SDKs</li>
              <li class="sec__link">Plugins</li>
              <li class="sec__link">Code samples</li>
            </ul>
          </div>

          <div class="sec__links--container">
            <h4 class="sec__title">GET STARTED</h4>

            <ul class="sec__links">
              <li class="sec__link">Accept online payments</li>
              <li class="sec__link">Manage subscriptions</li>
              <li class="sec__link">Send payments</li>
              <li class="sec__link">Set up in-person payments</li>
            </ul>
          </div>

          <ul class="subMenu__items sec--menu">
            <li class="subMenu--item">
              <img src="./assets/images/reference.svg" alt="" />
              <span class="label__container">
                <div class="label">
                  Full API reference
                  <img
                    class="right--arrow"
                    src="./assets/images/right-arrow.svg"
                    alt=""
                  />
                </div>
              </span>
            </li>

            <li class="subMenu--item">
              <img src="./assets/images/support.svg" alt="" />
              <span class="label__container">
                <div class="label">
                  Support
                  <img
                    class="right--arrow"
                    src="./assets/images/right-arrow.svg"
                    alt=""
                  />
                </div>
              </span>
            </li>
          </ul>

          <ul class="subMenu__items sec--menu">
            <li class="subMenu--item">
              <img src="./assets/images/status.svg" alt="" />
              <span class="label__container">
                <div class="label">
                  API status
                  <img
                    class="right--arrow"
                    src="./assets/images/right-arrow.svg"
                    alt=""
                  />
                </div>
              </span>
            </li>

            <li class="subMenu--item">
              <img src="./assets/images/changelog.svg" alt="" />
              <span class="label__container">
                <div class="label">
                  Changelog
                  <img
                    class="right--arrow"
                    src="./assets/images/right-arrow.svg"
                    alt=""
                  />
                </div>
              </span>
            </li>
          </ul>
        </div>

        <div id="company">
          <ul class="subMenu__items sec--menu">
            <li class="subMenu--item">
              <img src="./assets/images/about.svg" alt="" />
              <span class="label__container">
                <div class="label">
                  About stripe
                  <img
                    class="right--arrow"
                    src="./assets/images/right-arrow.svg"
                    alt=""
                  />
                </div>
              </span>
            </li>

            <li class="subMenu--item">
              <img src="./assets/images/customers.svg" alt="" />
              <span class="label__container">
                <div class="label">
                  Customers
                  <img
                    class="right--arrow"
                    src="./assets/images/right-arrow.svg"
                    alt=""
                  />
                </div>
              </span>
            </li>

            <li class="subMenu--item">
              <img src="./assets/images/enterprise.svg" alt="" />
              <span class="label__container">
                <div class="label">
                  Enterprise
                  <img
                    class="right--arrow"
                    src="./assets/images/right-arrow.svg"
                    alt=""
                  />
                </div>
              </span>
            </li>
          </ul>

          <ul class="subMenu__items sec--menu">
            <li class="subMenu--item">
              <img src="./assets/images/jobs.svg" alt="" />
              <span class="label__container">
                <div class="label">
                  Jobs
                  <img
                    class="right--arrow"
                    src="./assets/images/right-arrow.svg"
                    alt=""
                  />
                </div>
              </span>
            </li>

            <li class="subMenu--item">
              <img src="./assets/images/newsroom.svg" alt="" />
              <span class="label__container">
                <div class="label">
                  Newsroom
                  <img
                    class="right--arrow"
                    src="./assets/images/right-arrow.svg"
                    alt=""
                  />
                </div>
              </span>
            </li>

            <li class="subMenu--item">
              <img src="./assets/images/partners.svg" alt="" />
              <span class="label__container">
                <div class="label">
                  Partners
                  <img
                    class="right--arrow"
                    src="./assets/images/right-arrow.svg"
                    alt=""
                  />
                </div>
              </span>
            </li>
          </ul>

          <div class="more__info">
            <h4 class="sec__title">From the blog</h4>
            <div class="more__links">
              <a href="#">European payment methods available worldwide</a>
              <a href="#">We built Checkout so you don't have to</a>
              <a href="#">To design and develop an interactive globe</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </header>

  <div
    class="mt-3 mb-5 pb-5"
    :class="[
      [
        // 'Job Posting Manage Applicants',
        'Job Posting Form',
        'Leave Management Form',
      ].includes($route.name) || true
        ? 'container-fluid'
        : 'container',
    ]"
  >
    <div class="row">
      <div class="col">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AppComponent",

  setup() {},

  data() {
    return {
      VUE_APP_BACKEND_URL: process.env.VUE_APP_NCSOIS_MAIN_APP_URL,
      loading: true,
    };
  },

  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("application_state", [
      "breadcrumb",
      "modules",
      "navigations",
    ]),
  },

  async created() {},

  async mounted() {
    if (this.user && this.$route.query.hide_navigation != "true") {
      document.getElementById("navigation_bar").classList.remove("d-none");
    }

    if (this.user) {
      await this.getAuthModules();
    }

    // FOR NAVIGATION
    const handleMouseEnter = (e) => {
      if (!e.target.dataset.expand) {
        return;
      }

      navsVisited += 1;

      if (navsVisited === 1) {
        expandMenu.classList.add("new--expand");
        menus.forEach((menu) => menu.classList.add("first"));
        indicator.classList.add("first");
      } else {
        expandMenu.classList.remove("new--expand");
        menus.forEach((menu) => menu.classList.remove("first"));
        indicator.classList.remove("first");
      }

      navLinks.forEach((navLink) => {
        if (navLink === e.target) {
          navLink.classList.add("hover");
          currentNav = navLink;
        } else {
          navLink.classList.remove("hover");
        }
      });

      // const navLinkCenter = Math.floor(
      //   e.target.offsetLeft + e.target.clientWidth / 2
      // );

      let _navLinkCenter = e.pageX;
      if (e && e.target && e.target.getBoundingClientRect()) {
        _navLinkCenter =
          (e.target.getBoundingClientRect().left +
            e.target.getBoundingClientRect().right) /
          2;
      }
      const navLinkCenter = _navLinkCenter;

      indicator.style.transform = `translateX(${navLinkCenter}px)`;
      indicator.style.opacity = "1";

      const targetMenu = document.querySelector(`#${e.target.dataset.expand}`);
      const targetCoords = targetMenu.getBoundingClientRect();
      const { width: targetWidth, height: targetHeight } = targetCoords;

      expandMenu.style.width = targetWidth + "px";
      expandMenu.style.height = targetHeight + "px";

      const prevMenu = targetMenu.previousElementSibling;

      targetMenu.classList.remove("prev");

      if (prevMenu) {
        prevMenu.classList.add("prev");
      }

      menus.forEach((menu) => {
        if (menu.id === targetMenu.id) {
          menu.classList.add("active");
        } else {
          menu.classList.remove("active");
        }
      });

      expandMenu.classList.add("expand");
    };

    const handleMouseLeave = (e) => {
      if (isMouseOnMenu || e.y > 50) {
        return;
      }

      forceInitialState();
    };

    const forceInitialState = () => {
      expandMenu.classList.remove("expand", "active");
      currentNav.classList.remove("hover");
      menus.forEach((menu) => menu.removeAttribute("class"));
      indicator.style.opacity = "0";
      currentNav = null;
      navsVisited = 0;
    };

    const expandMenu = document.querySelector(".header__expandMenu");
    const menus = expandMenu.querySelectorAll(".menu__container > *");
    const navLinks = document.querySelectorAll(".nav--link");
    const indicator = document.querySelector(".tip");
    let isMouseOnMenu = false;
    let currentNav;
    let navsVisited = 0;

    // const {
    //   height: menuHeight,
    //   width: menuWidth,
    // } = expandMenu.getBoundingClientRect();

    navLinks.forEach((navLink) => {
      navLink.addEventListener("mouseenter", handleMouseEnter);
    });

    expandMenu.addEventListener("mouseenter", () => {
      if (expandMenu.style.opacity === "1") {
        isMouseOnMenu = true;
      }
    });

    expandMenu.addEventListener("mouseleave", (e) => {
      if (e.y > 70) {
        isMouseOnMenu = false;
        forceInitialState();
      }
    });

    document
      .querySelector(".nav__links")
      .addEventListener("mouseleave", handleMouseLeave);

    const sub_menu_items = expandMenu.querySelectorAll(".subMenu--item");
    sub_menu_items.forEach((sub_menu_item) => {
      sub_menu_item.addEventListener("click", () => {
        if (sub_menu_item.getAttribute("data-route-name"))
          this.pushRouter(sub_menu_item.getAttribute("data-route-name"));
      });
    });

    const logout_button = document.getElementById("logout_button");
    logout_button.addEventListener("click", () => {
      this.logout();
    });
  },

  watch: {
    // INFO: SETUP OF DOCUMENT TITLE
    // TODO watch route change
    async $route() {
      this.updatePageTitle();
    },

    breadcrumb: function () {
      this.updatePageTitle();
    },
  },

  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },

    pushRouter(route_name) {
      if (this.$route.name != route_name) {
        this.$router.push({
          name: route_name,
        });
      }
    },

    updatePageTitle() {
      let title = "Home";

      if (this.$route.name == "Login") title = "Login";
      else if (
        !["Main", "Login"].includes(this.$route.name) &&
        this.$route.meta.module &&
        this.$route.meta.component
      )
        title = `${this.$route.meta.module} ${this.$route.meta.component}`;
      else title = this.$route.name;

      if (
        this.$route.meta.breadcrumb &&
        this.$route.query[this.$route.meta.breadcrumb.id] &&
        this.breadcrumb &&
        this.breadcrumb.title
      )
        title = this.breadcrumb.title;

      document.title = title;
    },

    async getAuthModules() {
      const vm = this;
      await this.$store
        .dispatch("application_state/getAuthModules")
        .then(() => {
          vm.showHideNavigations(this.navigations, "sub__menu");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          vm.loading = false;
        });
    },

    // TODO: DI KO MAGAMIT YUNG NASA applicationState.js
    showHideNavigations(navigations, menu_class = "sub__menu") {
      var menus = document.getElementsByClassName(menu_class);
      Array.from(menus).forEach((element) => {
        const navigation_index = navigations.findIndex((navigation) => {
          return navigation.module === element.dataset.module;
        });
        if (
          navigation_index >= 0 &&
          navigations[navigation_index].show === true
        ) {
          element.classList.remove("d-none");
        } else {
          element.classList.add("d-none");
        }
      });
    },
  },
};
</script>


<style scoped>
/* .logo {
  font-weight: 600;
  font-size: 1.5rem;
} */
#logo {
  max-height: 75px;
  filter: invert(100%);
}

header {
  display: flex;
  padding: 1.5rem 3rem;
  justify-content: space-around;
  align-items: center;
}

.nav__links {
  display: flex;
  list-style: none;
  color: #fff;
  font-weight: 700;
}

.nav--link {
  padding: 0 1rem;
  transition: opacity 100ms linear;
  cursor: pointer;
}

.btn {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  font-family: inherit;
  font-weight: 700;
  color: #fff;
  outline: none;
  border: none;
  background-color: #ffffff35;
  border-radius: 2rem;
  transition: background-color 100ms linear;
  cursor: pointer;
}

.arrow {
  width: 20px;
  height: 10px;
  position: relative;
  transform: translateX(-4px);
}

.line {
  position: absolute;
  top: 46%;
  right: 0;
  height: 2px;
  width: 7px;
  background-color: #fff;
  transform-origin: 100% 50%;
}

.arrow--sideUp {
  transform: rotate(45deg);
}

.arrow--sideDown {
  transform: rotate(-45deg);
}

.arrow--hoverLine {
  width: 12px;
  transform: scale(0);
}

.arrow,
.line {
  transition: transform 100ms ease-in;
}

.btn--primary:hover {
  background-color: #ffffff65;
}

.btn:hover .arrow {
  transform: none;
}

.btn:hover .arrow--hoverLine {
  transform: scale(1);
}

.tip {
  width: 1.25rem;
  height: 1.25rem;
  background-color: #fff;
  position: absolute;
  top: 4.15rem;
  left: 0;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  transition: transform 100ms linear;
  opacity: 0;
}

.header__expandMenu {
  position: absolute;
  top: 4.5rem;
  left: 50%;
  width: 95%;
  height: 90%;
  transform-origin: 0%;
  transform: translateX(-50%) rotate3d(1, 0, 0, -15deg);
  background-color: white;
  border-radius: 0.5rem;
  perspective: 100px;
  transition: width 250ms ease, height 250ms ease, opacity 150ms ease,
    transform 200ms ease-in;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  z-index: 9999;
}

.menu__container {
  width: 100%;
  height: 100%;
  background-color: #ffb0f7;
}

.menu__container > * {
  position: absolute;
  top: 0;
  left: 50%;
  padding: 2rem;
  overflow: hidden;
  transform: translateX(-35%);
  transition: transform 250ms linear, opacity 250ms ease;
  opacity: 0;
}

#hr_system {
  display: grid;
  grid-template-columns: repeat(3, 300px);
}

#developers,
#company {
  display: grid;
  grid-template-columns: repeat(2, 225px);
  row-gap: 2rem;
}

.item--one {
  grid-column: span 2;
}

#use-cases {
  width: 450px;
}

.subMenu__items {
  list-style: none;
}

.title {
  font-size: 0.85rem;
  margin-bottom: 1.75rem;
}

.subMenu--item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.label__container {
  margin-left: 0.75rem;
}

.label {
  font-size: 0.9rem;
  font-weight: 700;
  color: rgba(0, 0, 73, 0.644);
}

.label--desc {
  font-size: 0.9rem;
  color: rgb(83, 83, 83);
  transform: translateY(-3px);
  transition: color 100ms ease;
}

.subMenu--item + .subMenu--item {
  margin-top: 1.5rem;
}

.icon--production {
  transform: translateY(-55%) scale(1.1);
}

.right--arrow {
  width: 12px;
  transform: translateY(1px) scale(0, 1);
  transition: transform 150ms ease-in-out;
}

.subMenu--item:hover .right--arrow {
  transform: translateY(1.75px) scale(1);
}

.subMenu--item:hover .label--desc {
  color: black;
}

.active {
  z-index: 1;
  opacity: 1;
  transform: translatex(-50%);
}

.prev {
  transform: translateX(-65%);
}

.expand {
  opacity: 1;
  pointer-events: all;
  transform: translateX(-50%) rotateX(0);
}

.hover {
  opacity: 0.5;
}

.new--expand {
  transition: opacity 150ms ease, transform 150ms ease-in;
}

.first {
  transition: none;
}

.sec--menu .subMenu--item + .subMenu--item {
  margin-top: 0.5rem;
}

.sec__title {
  color: grey;
  margin-bottom: 0.65rem;
}

.sec__links {
  list-style: none;
  padding: 0 0 0.2rem 0;
}

.sec__links > li {
  cursor: pointer;
  font-size: 0.85rem;
  font-weight: 400;
}

.sec__links--container {
  padding-left: 2rem;
}

.more__info {
  grid-column: span 2;
}

.more__links {
  padding-left: 1.5rem;
}

a {
  text-decoration: none;
  display: block;
  color: rgb(0, 0, 73);
}
a:hover {
  text-decoration: underline dotted;
}

@media (max-width: 1100px) {
  #hr_system {
    grid-template-columns: repeat(2, 300px);
    row-gap: 2rem;
    height: 660px;
  }
}
</style>

<!-- <style>
@import "./assets/app.scss";
</style> -->