import { createApp } from "vue";
import App from "./App.vue";

import created from "./created";

import store from "./store";
import router from "./router";

import VueAxios from "vue-axios";
import axios from "./axios";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

// Vuetify
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: "mdi", // This is already the default value - only for display purposes
  },
  theme: {
    
  }
});

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import { createVuestic } from "vuestic-ui";
import "vuestic-ui/css";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import VueSimpleContextMenu from "vue-simple-context-menu";
import "vue-simple-context-menu/dist/vue-simple-context-menu.css";

import "./assets/app.scss";

const app = createApp({
  extends: App,
  created,
});
app.use(store);
app.use(router);
app.use(VueAxios, axios);
app.use(vuetify);
app.component("VueDatePicker", VueDatePicker);
app.use(createVuestic());
app.use(VueSweetalert2);
app.component("vue-simple-context-menu", VueSimpleContextMenu);
app.mount("#app");

console.info("Version");
const pjson = require("../package.json");
console.info(`version: ${pjson.version}`);
