import { createStore } from "vuex";
import auth from "./auth/index.js";
import application_state from "./application_state/index.js";

export default createStore({
  modules: {
    auth,
    application_state,
  },
});
