import { createRouter, createWebHashHistory, } from "vue-router";
// import { nextTick } from "vue";
// import store from "../store";
import routes from "./routes/index";

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  let loggedIn = localStorage.getItem("user");
  // loggedIn = JSON.parse(loggedIn);

  // Check if application has a user logged in or not
  if (loggedIn && to.matched.some((route) => route.meta.auth)) {
    next();
  } else if (loggedIn) {
    if (to.name && to.name != "Login") {
      next();
    } else {
      next({ name: "Job Posting Datatable" });
    }
  } else if (to.name === "Login") {
    next();
  } else {
    next({ name: "Login" });
  }
});

// router.afterEach( async (to) => {
//   // await nextTick();
//   let title = `${to.meta.module} ${to.meta.component}`;
//   if (store.state.application_state.breadcrumb)
//     if (store.state.application_state.breadcrumb.title)
//       title += ` - ${store.state.application_state.breadcrumb.title}`;
//   document.title = title;
// });

export default router;
