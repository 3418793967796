const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../../pages/public/Login"),
  },
  {
    path: "/human-resource",
    name: "Human Resource",
    component: () => import("../../pages/main/HumanResourceIndex"),
    meta: {
      auth: true,
    },
    children: [
      {
        name: "Job Posting Index",
        path: "job-posting",
        component: () => import("../../components/JobPosting/Index"),
        children: [
          {
            name: "Job Posting Datatable",
            path: "datatable",
            meta: {
              module: "Job Posting",
              component: "Datatable",
            },
            component: () => import("../../components/JobPosting/Datatable"),
          },
          {
            name: "Job Posting Form",
            path: "form",
            meta: {
              module: "Job Posting",
              component: "Form",
              breadcrumb: {
                parent_name: "Job Posting Datatable",
                id: "job_posting_id",
              },
            },
            component: () => import("../../components/JobPosting/Form"),
          },
          {
            name: "Job Posting Masterlist",
            path: "masterlist",
            meta: {
              module: "Job Posting",
              component: "Masterlist",
              breadcrumb: {
                parent_name: "Job Posting Datatable",
              },
            },
            component: () => import("../../components/JobPosting/Masterlist"),
          },
          {
            name: "Job Posting Masterlist Summary",
            path: "masterlist-summary",
            meta: {
              module: "Job Posting",
              component: "Masterlist Summary",
              breadcrumb: {
                parent_name: "Job Posting Masterlist",
              },
            },
            component: () =>
              import("../../components/JobPosting/MasterlistSummary.vue"),
          },
          {
            name: "Job Posting Masterlist Summary Applicant Profile",
            path: "applicant-profile",
            meta: {
              module: "Job Posting",
              component: "Applicant Profile",
              breadcrumb: {
                parent_name: "Job Posting Masterlist Summary",
                id: "user_id",
              },
            },
            component: () =>
              import("../../components/JobPosting/ApplicantProfile.vue"),
          },
          {
            name: "Job Posting Manage Applicants",
            path: "manage-applicants",
            meta: {
              module: "Job Posting",
              component: "Manage Applicants",
              breadcrumb: {
                parent_name: "Job Posting Datatable",
                id: "job_posting_id",
              },
            },
            component: () =>
              import("../../components/JobPosting/ManageApplicants.vue"),
          },
          {
            name: "Job Posting Applicant Duplicate Application",
            path: "applicant-duplicate-application",
            meta: {
              module: "Job Posting",
              component: "Duplicate Application",
            },
            component: () =>
              import("../../components/JobPosting/DuplicateApplication.vue"),
          },
          {
            name: "Job Posting Applicant Assign Appointee",
            path: "applicant-assign-appointee",
            meta: {
              module: "Job Posting",
              component: "Assign Appointee",
            },
            component: () =>
              import("../../components/JobPosting/AssignAppointee.vue"),
          },
        ],
      },
      {
        name: "Leave Management Index",
        path: "leave-management",
        component: () => import("../../components/LeaveManagement/Index"),
        children: [
          {
            name: "Leave Management Datatable",
            path: "datatable",
            meta: {
              module: "Leave Management",
              component: "Datatable",
            },
            component: () => import("../../components/LeaveManagement/Datatable"),
          },
          {
            name: "Leave Management Form",
            path: "leave-management-form",
            meta: {
              module: "Leave Management",
              component: "Form",
              breadcrumb: {
                parent_name: "Leave Management Datatable",
                id: "leave_credit_header_id",
              },
            },
            component: () => import("../../components/LeaveManagement/LeaveManagementForm"),
          },
          {
            name: "Leave Card Form",
            path: "leave-card-form/:employee_id",
            meta: {
              module: "Leave Management",
              component: "Leave Card Form",
              breadcrumb: {
                parent_name: "Leave Management Datatable",
                id: 'hatodg'
              },
            },
            component: () => import("../../components/LeaveManagement/LeaveCardForm"),
          },
          {
            name: "Leave Card Detail Form",
            path: "leave-card-detail-form",
            meta: {
              module: "Leave Management",
              component: "Form",
            },
            component: () => import("../../components/LeaveManagement/LeaveCardDetailForm"),
          },
          {
            name: "Leave Card Sync Leave Transactions Form",
            path: "leave-card-sync-leave-transactions-form",
            meta: {
              module: "Leave Management",
              component: "Form",
            },
            component: () => import("../../components/LeaveManagement/LeaveCardSyncLeaveTransactionsForm"),
          },
          {
            name: "Leave Card Many Sync Leave Transactions Form",
            path: "leave-card-many-sync-leave-transactions-form",
            meta: {
              module: "Leave Management",
              component: "Leave Card Sync Leave Transactions",
              breadcrumb: {
                parent_name: "Leave Management Datatable",
              },
            },
            component: () => import("../../components/LeaveManagement/LeaveCardManySyncLeaveTransactionsForm"),
          },
          {
            name: "Leave Card Form Processes",
            path: "leave-card-form-processes",
            meta: {
              module: "Leave Management",
              component: "Leave Card Manual Processes",
              breadcrumb: {
                parent_name: "Leave Management Datatable",
                id: 'hatodg'
              },
            },
            component: () => import("../../components/LeaveManagement/BackgroundProcesses/LeaveCardFormProcesses"),
          },
        ],
      },
      {
        name: "Overtime Management Index",
        path: "overtime-management",
        component: () => import("../../components/Overtime/Index"),
        children: [
          {
            name: "Overtime Management Datatable",
            path: "datatable",
            meta: {
              module: "Overtime Management",
              component: "Datatable",
            },
            component: () => import("../../components/Overtime/Datatable"),
          },
          {
            name: "Overtime Monitoring Sheet",
            path: "overtime-monitoring-sheet",
            meta: {
              module: "Overtime Management",
              component: "Form",
              breadcrumb: {
                parent_name: "Overtime Management Datatable",
                id: "employee_id",
              },
            },
            component: () => import("../../components/Overtime/OvertimeMonitoringSheet"),
          },
          // {
          //   name: "Leave Card Form",
          //   path: "leave-card-form/:employee_id",
          //   meta: {
          //     module: "Leave Management",
          //     component: "Leave Card Form",
          //     breadcrumb: {
          //       parent_name: "Leave Management Datatable",
          //       id: 'hatodg'
          //     },
          //   },
          //   component: () => import("../../components/LeaveManagement/LeaveCardForm"),
          // },
          // {
          //   name: "Leave Card Detail Form",
          //   path: "leave-card-detail-form",
          //   meta: {
          //     module: "Leave Management",
          //     component: "Form",
          //   },
          //   component: () => import("../../components/LeaveManagement/LeaveCardDetailForm"),
          // },
          // {
          //   name: "Leave Card Sync Leave Transactions Form",
          //   path: "leave-card-sync-leave-transactions-form",
          //   meta: {
          //     module: "Leave Management",
          //     component: "Form",
          //   },
          //   component: () => import("../../components/LeaveManagement/LeaveCardSyncLeaveTransactionsForm"),
          // },
          // {
          //   name: "Leave Card Many Sync Leave Transactions Form",
          //   path: "leave-card-many-sync-leave-transactions-form",
          //   meta: {
          //     module: "Leave Management",
          //     component: "Leave Card Sync Leave Transactions",
          //     breadcrumb: {
          //       parent_name: "Leave Management Datatable",
          //     },
          //   },
          //   component: () => import("../../components/LeaveManagement/LeaveCardManySyncLeaveTransactionsForm"),
          // },
        ],
      },
    ],
  },
  {
    path: "/*",
    component: () => import("../../pages/main/Index"),
  },
];

export default routes;
