const breadcrumb = null;
// Global - Used for document.title - App.vue
// {
//   id: null,
//   title: null
// }

const modules = [];

// TODO: loading_navigations
const navigations = [
  {
    module: "Job Posting",
    show: false, // TODO
    required_modules: [
      "HR_JOB_REQUISITION",
      "HR_JOB_REQUISITION_APPLICATIONS",
      "HR_JOB_REQUISITION_MASTERLIST",
    ],
  },
  {
    module: "Leave Management",
    show: false, // TODO
    required_modules: ["HR_LEAVE_MANAGEMENT", "HR_LEAVE_CARD"],
  },
  {
    module: "Overtime Management",
    show: false, // TODO
    required_modules: ["HR_CTO_MANAGEMENT"],
  },
];

export default {
  breadcrumb,
  modules,
  navigations,
};
