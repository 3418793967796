// Equivalent to data in vue
import state from "./state.js";
// Equivalent to  computed in vue
import getters from "./getters.js";
// Actions are for asynchronous functions like axios requests
// Good for commiting a mutation after an asynchronous function
import actions from "./actions.js";
// Mutations are for making difference in state
import mutations from "./mutations.js";

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
