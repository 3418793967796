const updateBreadcrumb = (state, breadcrumb) => {
  state.breadcrumb = breadcrumb;
};

const updateModules = (state, modules) => {
  state.modules = modules.modules;
};

const updateNavigationShow = (state, navigation) => {
  state.navigations[navigation.index].show = navigation.show;
};

export default {
  updateBreadcrumb,
  updateModules,
  updateNavigationShow,
};
