import store from "./store/index";

const created = () => {
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
    store.commit("auth/setUser", user);
    store.dispatch("auth/getAuth");
  }
};

export default created;
